import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PagesServiceService } from '../pages-service.service';
import { PreviewXpcnComponent } from '../preview-xpcn/preview-xpcn.component';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-print-lr-tracking',
  templateUrl: './print-lr-tracking.component.html',
  styleUrls: ['./print-lr-tracking.component.scss']
})
export class PrintLrTrackingComponent implements OnInit {

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  filterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  OrderList = [];
  filterBy = '';
  keyword = '';
  keywordDate = '';
  dateFilter = '';
  user;
  asn_no = '';

  filterToggle: boolean = true;
  CustomDate: boolean = false;
  excelData: any[];


  constructor(private fb: FormBuilder,
    private _pageService: PagesServiceService,
    private _datePipe: DatePipe,
    public _activeRoute: ActivatedRoute,
    public alertService: AlertService,
    public dialog: MatDialog,
    public _downloadExcelService: DownloadExcelService
  ) {
    if(this._activeRoute.snapshot.queryParams['n'] != null) {
      this.asn_no = this._activeRoute.snapshot.queryParams['n'];
      console.log(this.asn_no,'print lr tracking ')
     }
    this.user = JSON.parse(localStorage.getItem('userSession'));
    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      FilterBy: new FormControl(""),
      Keyword: new FormControl(""),
      KeywordDate: new FormControl(""),
      OrderByDirection: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      DateType: new FormControl("")
    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
  }

  ngOnInit(): void {
    this.filterForm.controls["DateType"].setValue('YTD');
    if (this.asn_no != null || this.asn_no != '') {
      this.filterForm.controls['FilterBy'].setValue('asnno');
      this.filterForm.controls['Keyword'].setValue(this.asn_no);
    }else{
      this.filterForm.controls['FilterBy'].setValue(null);
      this.filterForm.controls['Keyword'].setValue(null);
    }
    this.getASnList();

  }

  getASnList() {
    this._pageService.GetPrintYourLr(this.filterForm.value).subscribe((res) => {
      // console.log(res["Data"]);
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalCount;
        this.OrderList.forEach(x => {
          if (x['XPCNId'] != null) {
            x.EncryptedOrderId = btoa(x['OrderId'].toString());
            x.EncryptedXPCNId = btoa(x['XPCNId'].toString());
            x.EncryptedASN = btoa(x['ASN_Num'].toString());
          }
        });
      }
    })
  }


  openLCLXPCNPreview(XPCNID) {
    this._pageService.getLCLXPCNDetailsForFinzalization(XPCNID).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(PreviewXpcnComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  filter() {
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['FilterBy'].setValue(this.filterBy);
      if (this.filterBy != 'asndate' && this.filterBy != 'update' && this.filterBy != 'xpcndate') {
        if (this.keyword.trim() != '' && this.keyword != null) {
          this.filterForm.controls['Keyword'].setValue(this.keyword.trim());
        } else {
          this.alertService.createAlert("Please enter keyword", 0);
          return;
        }
        this.keywordDate = null;
      } else {
        this.keyword = null;
        if (this.keywordDate != '' && this.keywordDate != null) {
          this.filterForm.controls['KeywordDate'].setValue(this._datePipe.transform(this.keywordDate, 'yyyy-MM-dd'));
        } else {
          this.alertService.createAlert("Please select date", 0);
          return;
        }
      }
      this.getASnList();
    } else {
      this.alertService.createAlert("Please select filter by", 0);
      return;
    }
  }

  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.filterBy = '';
    this.keyword = null;
    this.keywordDate = null;
    this.filterForm.controls['FilterBy'].setValue(null);
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls['OrderBy'].setValue(null);
    this.filterForm.controls['OrderByDirection'].setValue(null);
    this.filterForm.controls['KeywordDate'].setValue(null);
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.getASnList();
  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getASnList();
  }

  printStickers(id, code, width) {
    window.open(environment.apiUrl + 'InvoiceView/DownloadConsignorStickers?id=' + id + "&code=" + code + "&width=" + width, "_blank");
  }

  fromDate
  toDate

  dateFilterChanged(type) {

    this.filterForm.controls["DateType"].setValue(null);

    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.dateFilter = type;
    this.filterForm.controls["DateType"].setValue(this.dateFilter);

    if (type == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    } else {
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);

      this.fromDate = null;
      this.toDate = null;
    }

    this.getASnList();

  }

  datefilterclear() {
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.controls["DateType"].setValue(null);
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.getASnList();
  }

  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.filterForm.controls['EndIndex'].setValue(this.totalSize);
    this._pageService.GetPrintYourLr(this.filterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data'];
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({
              'ASN No': element['ASN_Num'],
              'ASN Date': this._datePipe.transform(element['BookingDate'], 'dd-MM-y, (HH:mm)'),
              'XP Booking Id': element['BookingIdCode'],
              'Vendor Code': element['VendorCode'],
              'Vendor Name': element['VendorName'],
              'Vendor Location': element['VendorLoc'],
              'XPCN No': element['XPCNCode'],
              'XPCN Generation Date': this._datePipe.transform(element['XPCNDate'], 'dd-MM-y, (HH:mm)'),
              'No Of Packages': element['NoOfBoxes'],
              'DC Site Code': element['DcCode'],
              'DC Site Location': element['DcLoc'],
              'Status': element['Status'],
              'RIL Business': element['BusinessType'],
              'Delivery Type': element['DeliveryType']
            })
          });
        }
        else {
          this.excelData.push({
            'ASN No': '-',
            'ASN Date': '-',
            'XP Booking Id': '-',
            'Vendor Code': '-',
            'Vendor Name': '-',
            'Vendor Location': '-',
            'XPCN No': '-',
            'XPCN Generation Date': '-',
            'No Of Packages': '-',
            'DC Site Code':'-',
            'DC Site Location':'-',
            'Status': '-'
          })
        }

        this._downloadExcelService.exportAsExcelFile(this.excelData, 'Print Stickers');
      }
    })
  }


}





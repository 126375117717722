import { DatePipe } from '@angular/common';
import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PagesServiceService } from '../pages-service.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';

@Component({
  selector: 'app-download-mis',
  templateUrl: './download-mis.component.html',
  styleUrls: ['./download-mis.component.scss']
})
export class DownloadMisComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  @ViewChild('select1') select1: MatSelect;

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  filterForm: FormGroup;
  downloadFilterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  OrderList = [];
  filterBy = '';
  keyword = '';
  keywordDate = '';
  dateFilter = '';
  filterToggle: boolean = false;
  CustomDate: boolean = false;
  consignors = [];
  fromDate;
  toDate;
  filterVendors = [];
  filterStatus = [];
  filterCategory = [];
  excelData = [];
  allSelected = false;
  allSelected1 = false;
  Search;
  public readonly MAX_NUMBER_OF_DAYS_FOR_MIS_DATA = 30;

  constructor(private fb: FormBuilder,
    private _pageService: PagesServiceService,
    public alertService: AlertService,
    private _datePipe: DatePipe,
    public dialog: MatDialog,
    public _downloadExcelService: DownloadExcelService
  ) {
    this.filterForm = this.fb.group({
      OrderBy: [],
      StartIndex: [],
      EndIndex: [],
      ConsignorIds: [],
      PickupStatus: [],
      Category: [],
      FromDate: [],
      ToDate: []
    });
    this.downloadFilterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      ConsignorIds: new FormControl(""),
      PickupStatus: new FormControl(""),
      Category: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl("")
    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
  }

  ngOnInit(): void {
    this.getConsignor();
    //this.getMIS(this.filterForm.value);
  }

  inputNotAllowed() {
    return false;
  }

  getConsignor() {
    this._pageService.getConsignor().subscribe((res) => {
      this.consignors = res["Data"];
    })
  }

  getMIS(filter) {
    this._pageService.GetConsignorMIS(filter).subscribe((res) => {
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalCount;
      }
    })
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  toggleSelectAll() {
    if (this.filterVendors.includes('selectAll')) {
      // If "Select All" is already selected, deselect all items
      this.filterVendors = [];
    } else {
      // Otherwise, select all items
      this.filterVendors = ['selectAll', ...this.consignors.map(item => item.CustomerConsigneeId)];
    }
  }
  selectAllItems() {
    if (this.filterVendors.length === this.consignors.length) {
      // If all items are already selected, deselect all
      this.filterVendors = [];
    } else {
      // Otherwise, select all items
      this.filterVendors = this.consignors.map(item => item.CustomerConsigneeId);
    }
  }

  onItemSelect(itemValue: string) {
    if (this.filterVendors.includes('selectAll')) {
      // If "Select All" is selected, remove it when any other option is selected
      this.filterVendors = this.filterVendors.filter(item => item !== 'selectAll');
    }
  }

  onItemDeselect(itemValue: string) {
    if (this.filterVendors.length === 0) {
      // If no items are selected, select "Select All"
      this.filterVendors = ['selectAll'];
    } else if (this.filterVendors.includes('selectAll')) {
      // If "Select All" is selected and another item is deselected, deselect "Select All"
      this.filterVendors = this.filterVendors.filter(item => item !== 'selectAll');
    }
  }

  toggleAllSelection1() {
    this.allSelected1 = !this.allSelected1;
    if (this.allSelected1) {
      this.select1.options.forEach((item: MatOption) => item.select());
    } else {
      this.select1.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick1() {
    let newStatus = true;
    this.select1.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected1 = newStatus;
  }

  filter() {
    let valid = false;
    if ((this.fromDate == '' || this.fromDate == null)
      && (this.toDate == '' || this.toDate == null)
      && this.filterVendors.length == 0
      && this.filterStatus.length == 0
      && this.filterCategory.length == 0) {
      this.alertService.createAlert("Please select atleast one filter", 0);
    } else {
      if (this.fromDate != null) {
        this.filterForm.controls['FromDate'].setValue(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd'));
        if (this.toDate != null) {
          if (this.fromDate <= this.toDate) {
            this.filterForm.controls['ToDate'].setValue(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'));
            valid = true;
          } else {
            this.alertService.createAlert("To Date must be grater than From Date", 0);
          }
        } else {
          this.alertService.createAlert("Please select To Date", 0);
        }
      }
      if (this.filterVendors.length > 0) {
        this.filterForm.controls['ConsignorIds'].setValue(this.filterVendors);
        valid = true;
      }
      if (this.filterStatus.length > 0) {
        this.filterForm.controls['PickupStatus'].setValue(this.filterStatus);
        valid = true;
      }
      if(this.filterCategory.length>0){
        this.filterForm.controls['Category'].setValue(this.filterCategory);
      }
      if (valid)
        this.getMIS(this.filterForm.value);
    }
  }

  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.fromDate = null;
    this.toDate = null;
    this.filterVendors = [];
    this.filterStatus = [];
    this.filterCategory = [];
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.filterForm.controls["ConsignorIds"].setValue(this.filterVendors);
    this.filterForm.controls["PickupStatus"].setValue(this.filterStatus);
    this.filterForm.controls['Category'].setValue(this.filterCategory);
    // this.getMIS(this.filterForm.value);
    this.OrderList = [];
  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getMIS(this.filterForm.value);
  }

  public exportAsExcel() {
    this.excelData = [];
    let list = [];
    let from = this.filterForm.controls["FromDate"].value != null && this.filterForm.controls["FromDate"].value != '' ?
    this._datePipe.transform(this.filterForm.controls["FromDate"].value, 'yyyy-MM-dd') : null;
    let to = this.filterForm.controls["ToDate"].value != null && this.filterForm.controls["ToDate"].value != '' ?
    this._datePipe.transform(this.filterForm.controls["ToDate"].value, 'yyyy-MM-dd') : null;
    if(from <= to){
      const differenceInMilliseconds: number = this.toDate.getTime() - this.fromDate.getTime();
      const differenceInDays: number = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      if (differenceInDays > this.MAX_NUMBER_OF_DAYS_FOR_MIS_DATA) {
        this.alertService.createAlert("Please select date range within "+ this.MAX_NUMBER_OF_DAYS_FOR_MIS_DATA + " days", 0);
      }
      else{
        this.downloadFilterForm.controls['StartIndex'].setValue(1);
        this.downloadFilterForm.controls['EndIndex'].setValue(this.totalSize);
        this.downloadFilterForm.controls['ConsignorIds'].setValue(this.filterForm.controls['ConsignorIds'].value);
        this.downloadFilterForm.controls['PickupStatus'].setValue(this.filterForm.controls['PickupStatus'].value);
        this.downloadFilterForm.controls['Category'].setValue(this.filterForm.controls['Category'].value);
        this.downloadFilterForm.controls['FromDate'].setValue(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd'));
        this.downloadFilterForm.controls['ToDate'].setValue(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'));
        this._pageService.GetConsignorMIS(this.downloadFilterForm.value).subscribe(data => {
          if (data != null) {
            list = data['Data'];
            if (list.length > 0) {
              list.forEach(element => {
                this.excelData.push({
                  'ASN No': element['ASN_Num'],
                  'Deliv. date(From/to)': this._datePipe.transform(element['BookingDate'], 'dd-MM-y'),
                  'Vendor Code': element['VendorCode'],
                  'Vendor Name': element['VendorName'],
                  'Created By': element['CreatedBy'],
                  'Boxes ': element['NoOfBoxes'],
                  'Vendor Location': element['VendorLoc'],
                  'Warehouse Number': element['WarehouseNo'],
                  'External Delivery ID': element['ExtDelId'],
                  'Origin Zone': element['OriginRegion'],
                  'Origin State': element['OriginState'],
                  'DC Site Code': element['DcCode'],
                  'DC Site Location': element['DcLoc'],
                  'DC Zone': element['DCZone'],
                  'Delivery State': element['DeliveryState'],
                  'Pickup Days': element['PickupDays'],
                  'Pickup Status': element['PickupStatus'],
                  'Pickup Remarks': element['PickupRemarks'],
                  'XPCN': element['XPCNCode'],
                  'XPCN Creation Date (By Vendor)': this._datePipe.transform(element['XPCNDate'], 'dd-MM-y'),
                  'Actual Pickup Date (XP)': this._datePipe.transform(element['PickedDate'], 'dd-MM-y'),
                  'No. of Packages': element['NoOfBoxes'],
                  'Booking Month': this._datePipe.transform(element['BookingDate'], 'MMMM'),
                  'EDD ': element['PickupStatus'] == 'Pickup Done' ? this._datePipe.transform(element['EDD'], 'dd-MM-y') : '',
                  'TAT ': element['TAT'],
                  'Out for Delivery ': this._datePipe.transform(element['ADA'], 'dd-MM-y'),
                  'Halting At DC': element['HaltingDays'],
                  'ADD': this._datePipe.transform(element['DeliveryDate'], 'dd-MM-y'),
                  'EDD Status': element['EDDStatus'],
                  'Delivery Status': element['DelStatus'],
                  'POD Status': element['PodDocUrl'],
                  'Freight Cost': element['Freight'],
                  'Actual Weight': element['AcutalWeight'],
                  'Chargeable weight': element['ChargedWeight'],
                  'Invoice Value': element['InvoiceValue'],
                  'RIL Business' : element['BusinessType'],
                  'Deps Remarks': '',
                  'LBH1': element['LBH']
                })
              });
            }
            else {
              this.excelData.push({
                'ASN No': '-',
                'ASN Date': '-',
                'Vendor Code': '-',
                'Vendor Name': '-',
                'Created By': '-',
                'Boxes ': '-',
                'Vendor Location': '-',
                'Warehouse Number': '-',
                'External Delivery ID': '-',
                'DC Site Code': '-',
                'DC Site Location': '-',
                'DC Zone': '-',
                'Origin Zone': '-',
                'Pickup Status': '-',
                'Pickup Remarks': '-',
                'Docket Number': '-',
                'Docket Creation Date (By Vendor)': '-',
                'Actual Pickup Date (XP)': '-',
                'Booking Month': '-',
                'EDD ': '-',
                'TAT ': '-',
                'Out for Delivery': '-',
                'Halting At DC': '-',
                'ADD ': '-',
                'EDD Status': '-',
                'Delivery Status': '-',
                'POD Status': '-',
                'Freight Cost': '-',
                'Acutal Weight': '-',
                'Chargeable weight': '-',
                'RIL Business': '-',
                'Deps Remarks': '-'
              })
            }
    
            this._downloadExcelService.exportAsExcelFile(this.excelData, 'MIS');
          }
        })
      }
    }

  }  
  
  dateFilterChanged(type) {
    var today = new Date();
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    if(type == 'Weekly') {
      this.toDate = moment().startOf('week').toDate();
      this.fromDate = this.addDays(today, -7);
    }
    else if (type == 'Fortnightly') {
      let currentDay = parseInt(String(today.getDate()).padStart(2, '0'));
      if (currentDay > 15) {
        this.fromDate = moment().startOf('month').toDate();
        this.fromDate = this.addDays(this.fromDate, 15);
        this.toDate = moment().endOf('month').toDate();
      }
      else {
        this.fromDate = moment().startOf('month').toDate();
        //   this.toDate = this.fromDate.getDate() + 15;
        this.toDate = this.addDays(this.fromDate, 14);
      }
    }
    else if (type == 'MTD') {
      this.fromDate = moment().startOf('month').toDate();
      this.toDate = moment().endOf('month').toDate();
    }
    else if (type == 'QTD') {
      this.fromDate = moment().startOf('quarter').toDate();
      this.toDate = moment().endOf('quarter').toDate();
    }
    else if (type == 'YTD') {
      let temp = new Date().getMonth() + 1;
      if (temp > 3) {
        let year1 = new Date().getFullYear();
        let year2 = year1 + 1;
        let aprildate = "04" + "-" + "01" + "-" + year1;
        let marchdate = "03" + "-" + "31" + "-" + year2;
        this.fromDate = new Date(aprildate);
        this.toDate = new Date(marchdate);
      }
      else {
        let year1 = new Date().getFullYear();
        let year2 = year1 - 1;
        let aprildate = "04" + "-" + "01" + "-" + year2;
        let marchdate = "03" + "-" + "31" + "-" + year1;
        this.fromDate = new Date(aprildate);
        this.toDate = new Date(marchdate);

      }
    }
    else if (type == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } 
      // else {
      //   this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
      //   this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      // }
    }
    this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
    this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));

    this.getMIS(this.filterForm.value);

  }

  datefilterclear() {
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.controls["DateType"].setValue(null);
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

  }
  addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  
}


@Pipe({
  name: 'stringFilterBy'
})
export class StringFilterByPipe implements PipeTransform {

  transform(arr: any[], searchText: string, fieldName?: string): any[] {
    if (!arr) return [];
    if (!searchText) return arr;
    if (searchText.length > 0) {
      searchText = searchText.toLowerCase();
    }
    return arr.filter((it: any) => {
      if (typeof it == 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (typeof it == 'number') {
        return it.toString().toLowerCase().includes(searchText);
      } else {
        return it[fieldName].toLowerCase().includes(searchText);
      }

    });
  }
}
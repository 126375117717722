<div class="NewHeader">
  <i class="fa fa-times" aria-hidden="true"
    style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
  <h1 mat-dialog-title> Preview XPCN</h1>
</div>
<div mat-dialog-content style="margin: 0px !important; padding: 0px !important" [innerHTML]="invoiceHtml">

</div>
<mat-dialog-actions align="end">
  <button *ngIf="xpcn.strXPCNDate != null && xpcn.strXPCNDate != ''" type="button" class="btn btn-success fa fa-download" (click)="downloadPDF()" style="margin-right: 10px;">
    <span> Download PDF</span>
  </button>

  <button class="btn btn-danger fa fa-times" (click)="close()">
    <span> Close</span>
  </button>
</mat-dialog-actions>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule, routing } from './app.routing';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { OrderDashboardComponent } from './pages/orders/order-dashboard/order-dashboard.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { ServiceComponent } from './pages/service/service.component';
import { FinanceComponent } from './pages/finance/finance.component';
import { ChatComponent } from './pages/chat/chat.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { LocalstorageService } from './services/localstorage.service';
import { MessagingService } from './services/messaging-service';
import { AlertService } from './shared/services/alert.service';
import { SessionGuard } from './_gaurds/session.guard';
import { HttpInterceptorJWTService } from './services/http-interceptor-jwt.service';
import { HttpInterceptorLoaderService } from './services/http-interceptor-loader.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GoogleMapsAPIWrapper,AgmCoreModule } from '@agm/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { SharedModule } from './pages/shared/shared.module';
import { UserProfileComponent } from './theme/components/user-menu/user-profile/user-profile.component';
import { ChangePasswordComponent } from './theme/components/user-menu/change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMultipleDatesModule } from 'ngx-multiple-dates';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { environment } from "src/environments/environment";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilizationComponent } from './pages/utilization/utilization.component';
import { ConsginorAddressBookComponent } from './pages/consginor-address-book/consginor-address-book.component';
import { AsnListComponent } from './pages/asn-list/asn-list.component';
import { PrintLrTrackingComponent } from './pages/print-lr-tracking/print-lr-tracking.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { TrackLrComponent } from './pages/track-lr/track-lr.component';
import { DeliveredComponent } from './pages/delivered/delivered.component';
import { GenerateLrComponent, NumberToWordsPipe } from './pages/generate-lr/generate-lr.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PreviewXpcnComponent } from './pages/preview-xpcn/preview-xpcn.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UploadCsvDialogComponent } from './pages/upload-csv-dialog/upload-csv-dialog.component';
import { UploadCsvResultDialogComponent } from './pages/upload-csv-result-dialog/upload-csv-result-dialog.component';
import { DownloadMisComponent, StringFilterByPipe } from './pages/download-mis/download-mis.component';
import { MatSelectSearchModule } from './_library/mat-select-search/mat-select-search.module';
import { DemoVideoComponent } from './pages/demo-video/demo-video.component';
import { DemoVideoLanguageComponent } from './pages/demo-video-language/demo-video-language.component';
import { CancelledAsnComponent } from './pages/cancelled-asn/cancelled-asn.component';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
@NgModule({
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserModule,NgbModule,
    MatDialogModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    RouterModule,
    MatSelectSearchModule,
    NgxMultipleDatesModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,MatCardModule,
    MatInputModule,CommonModule, MatSelectModule,MatCheckboxModule,
    SharedModule,FormsModule, ReactiveFormsModule,NgbTooltipModule,MatPaginatorModule ,MatTooltipModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCVTDpqKxLeXLDJJxzv3yq3lCSDYTZrYU4'
      /* apiKey is required, unless you are a
      premium customer, in which case you can
      use clientId
      */
    }),
    routing,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
        VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    UserMenuComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    FlagsMenuComponent,
    OrdersComponent,
    CustomersComponent,
    ServiceComponent,
    FinanceComponent,
    ChatComponent,
    NotificationsComponent,
    AnalyticsComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    UtilizationComponent,
    ConsginorAddressBookComponent,
    AsnListComponent,
    PrintLrTrackingComponent,
    TrackLrComponent,
    DeliveredComponent,
    GenerateLrComponent,
    PreviewXpcnComponent,
    NumberToWordsPipe,
    UploadCsvDialogComponent,
    UploadCsvResultDialogComponent,
    DownloadMisComponent,
    StringFilterByPipe,
    DemoVideoComponent,
    DemoVideoLanguageComponent,
    CancelledAsnComponent,
    
  ],
  providers: [
    AppSettings,
    MessagingService, AsyncPipe,
    AppSettings,
    AlertService,
    GoogleMapsAPIWrapper,
    SessionGuard,
    LocalstorageService,
    DatePipe,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorJWTService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorLoaderService, multi: true },
    //{ provide: LocationStrategy, useClass: PathLocationStrategy } // Use PathLocationStrategy
  ],
  exports:[MatDialogModule, 
    MatProgressSpinnerModule],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagesServiceService {
  headers: { headers: any; };
  constructor(private _http: HttpClient) { }
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }
  GetAsnList(data) {
    return this._http.post(environment.apiUrl + 'api/get-asn-list',data);
  }
  GetTrackyourLr(data) {
    return this._http.post(environment.apiUrl + 'api/get-track-your-lr',data);
  }
  GetPrintYourLr(data) {
    return this._http.post(environment.apiUrl + 'api/get-print-lr',data);
  }
  GetDeliveredAsnList(data) {
    return this._http.post(environment.apiUrl + 'api/get-delivered-lr',data);
  }
  GetCancelledAsnList(data) {
    return this._http.post(environment.apiUrl + 'api/get-cancelled-asn-list',data);
  }
  GetConsignorMIS(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/consignor/get-consignor-mis-report',data);
  }
  downloadBulkPOD(data) {
    return this._http.post(environment.apiUrl + 'InvoiceView/DownloadBulkPod',data);
  }
  generateLRPickUpNumber(bookingId, boxes){
    return this._http.get(environment.apiUrl + 'api/insert-lcl-xpcn-and-consignor-details?bookingId=' + bookingId+'&boxes='+ boxes);
  }

  getLCLXPCNDetailsForFinzalization(int_lcl_xpcn_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-details-for-finalization?int_lcl_xpcn_id=' + int_lcl_xpcn_id);
  }

  checkxpcnfinalizestatus(ID){
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-finalise-status?xpcnId='+ ID)
  }

  searchLCLEWayBill(xpcnId,ewaybillNo,consignorStateId){
    return this._http.get(environment.apiUrl + 'api/search-lcl-ewaybill-consignor?xpcnId=' + xpcnId + '&ewaybillNo=' + ewaybillNo+'&consignorStateId='+consignorStateId+'&isEditingXPCN=true');
  }

  //to get the list of states to bind the dropdown
  GetStatesDropdownlist() {
    return this._http.get(environment.apiUrl + 'api/get-stateslist')
  }

  //to get the list of districts by stateid's
  GetDistrictsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-districtlistbystateid?StateId=' + data)
  }

  // to get the list of locations by districtid's
  GetLocationsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-locationlistbystateid?DistrictId=' + data)
  }

  // to get the list of locations by districtid's
  GetPostcodesDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-postcodes-by-location?LocationId=' + data)
  }

  finalizeLCLXPCN(data){
    return this._http.post(environment.apiUrl + 'api/finalize-lcl-xpcn', data);
  }

  upsertLCLOrderXPCNBoxes(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-lcl-order-xpcn-boxes', data);
  }

  upsertLCLOrderXPCNBoxesAll(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-lcl-order-xpcn-boxes-all-including-deleted', data);
  }

  getLCLOrderXPCNBoxes(int_lcl_xpcn_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-boxes?int_lcl_xpcn_id=' + int_lcl_xpcn_id);
  }

  deleteXPCNBoxesConsignor(boxId) {
    return this._http.get(environment.apiUrl + 'api/delete-xpcn-box-consignor?boxId=' + boxId);
  }

  getConsigneeDetails() {
    return this._http.get(environment.apiUrl + 'api/get-consignee-for-finalize-lcl-xpcns')
  }
  getConsigneeDetailsbyId(id) {
    return this._http.get(environment.apiUrl + 'api/get-consignee-for-finalize-lcl-xpcns-by-id/' + id)
  }
  CheckGatewayForDistrict(id,xpcnId) {
    return this._http.get(environment.apiUrl + 'api/get-geteway-by-consignee-district?districtId=' + id +'&xpcnId=' + xpcnId);
  }

  saveLCLXPCNWaybill(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-xpcn-waybill-lcl', data);
  }

  saveLCLXPCNInvoice(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-xpcn-invoice-lcl', data);
  }
  uploadBookOrderRILCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-book-order-lcl-as-csv', data);
  }

  getConsignor() {
    return this._http.get(environment.apiUrl + 'api/get-consignor-details');
  }
}

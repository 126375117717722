<div class="">
    <div class="card card-body ">
        <div class="row mt-2">
            <div class="col">
                <div widget class="card border-0 box-shadow">
                    <div class="card-body widget-body">
                        <p style="margin: 0px;">
                            <span>ASN No: {{asnNo}}</span>
                            <span style="margin-left: 20px;">Consignor Code: {{vendorCode}}</span>
                            <span style="margin-left: 20px;">XPCN No: {{xpcnNo}}</span>

                            <button class="btn btn-primary pull-right fa fa-arrow-left" (click)="back()"> Back</button>

                        </p>
                    </div>
                </div>

            </div>
        </div>
        <form class="form-horizontal" novalidate [formGroup]="addEditForm">
            <div class="row mt-2">
                <div class="col">
                    <div widget class="card border-0 box-shadow">
                        <div class="card-body widget-body">
                            <div class="row form-inline">
                                <div class="form-group col-xl-1 col-sm-6 col-12" style="color: black;">Pickup Location:
                                </div>
                                <div class="form-group col-xl-2 col-sm-6 col-12">
                                    <input type="text" disabled class="form-control" placeholder="Enter Origin"
                                        formControlName="From">
                                    <!-- <mat-select placeholder="Pickup Location" style="width: 100%; border: 1px solid black;"
                                    onselect="reset()" placeholder="Pickup Location" 
                                    class="form-control">
                                    <mat-option value="any">Delhi</mat-option>
                                    <mat-option value="any">Gurgaon</mat-option>
                                    <mat-option value="any">Noida</mat-option>
                                </mat-select> -->
                                </div>
                                <div class="form-group col-xl-1 col-sm-6 col-12" style="color: black;">Destination:
                                </div>
                                <div class="form-group col-xl-2 col-sm-6 col-12">
                                    <input type="text" disabled class="form-control" placeholder="Enter Destination"
                                        formControlName="To">
                                    <!-- <mat-select placeholder="Destination" style="width: 100%; border: 1px solid black;"
                                    onselect="reset()" placeholder="Destination" 
                                    class="form-control">
                                    <mat-option value="any">Delhi</mat-option>
                                    <mat-option value="any">Gurgaon</mat-option>
                                    <mat-option value="any">Noida</mat-option>
                                </mat-select> -->
                                </div>
                                <div class='col-sm-3'>
                                    <div class="form-check form-check-inline">
                                        Invoice Value Below or equal Rs. 50K:&nbsp;&nbsp;
                                        <!-- &nbsp;&nbsp; -->
                                        <input class="form-check-input" type="radio" name="InvValueBelow50k"
                                            id="inlineRadio1" value=true formControlName="InvValueBelow50k"
                                            (change)="invValChanged($event.target.value)" />
                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input class="form-check-input" type="radio" name="InvValueBelow50k"
                                            id="inlineRadio2" value=false formControlName="InvValueBelow50k"
                                            (change)="invValChanged($event.target.value)" />
                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                    </div>
                                </div>

                                <div class="form-group col-sm-3">
                                    <input type="text" class="form-control" placeholder="Enter Ewaybill No"
                                        formControlName="SearchEWB" maxlength="12" style="margin-right: 20px;" />
                                    <button class="btn btn-primary fa fa-search" (click)="searchEwaybill()">
                                        <span> Search</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="row mt-2">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-4" style="display: inline-block;">
                            <div widget class="card border-0 box-shadow">
                                <div class="card-body widget-body">
                                    <h4 style="color: #3E50B4; border-bottom: 1px dotted;">Consignor</h4>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Name:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" formControlName="NameOfConsignor" class="form-control"
                                                placeholder="Name" required>
                                            <div *ngIf="!addEditForm.controls.NameOfConsignor.valid && (addEditForm.controls.NameOfConsignor.dirty || addEditForm.controls.NameOfConsignor.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.NameOfConsignor.errors?.required">
                                                    Name is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Address Line1:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" formControlName="ConsignorAddress"
                                                maxlength="500" placeholder="Enter Address" required />
                                            <div *ngIf="!addEditForm.controls.ConsignorAddress.valid && (addEditForm.controls.ConsignorAddress.dirty || addEditForm.controls.ConsignorAddress.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.ConsignorAddress.errors?.required">
                                                    Address is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Address Line2:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" placeholder="Enter Address"
                                                formControlName="ConsignorAddress2" />
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Pin:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" formControlName="ConsignorPostcode" class="form-control"
                                                placeholder="Enter Pin" required>
                                            <div *ngIf="!addEditForm.controls.ConsignorPostcode.valid && (addEditForm.controls.ConsignorPostcode.dirty || addEditForm.controls.ConsignorPostcode.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.ConsignorPostcode.errors?.required">
                                                    Pin is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Phone:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" formControlName="ConsignorMobile"
                                                (keypress)="onlyNumbers($event)" minlength="10" maxlength="10"
                                                class="form-control" placeholder="Enter Mobile No">
                                            <div *ngIf="!addEditForm.controls.ConsignorMobile.valid && (addEditForm.controls.ConsignorMobile.dirty || addEditForm.controls.ConsignorMobile.touched) "
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.ConsignorMobile.errors?.required">
                                                    Mobile No is required
                                                </div>
                                                <div *ngIf="addEditForm.controls.ConsignorMobile.errors.minlength">
                                                    Mobile No should contain 10 numbers
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Email:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" email maxlength="50" formControlName="ConsignorEmail"
                                                class="form-control" placeholder="Enter Email ID">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-5" style="text-align: right;">
                                            GSTN:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" formControlName="ConsignorGSTIN" maxlength="15"
                                                class="form-control" placeholder="Enter GSTIN" required>
                                            <div *ngIf="!addEditForm.controls.ConsignorGSTIN.valid && (addEditForm.controls.ConsignorGSTIN.dirty || addEditForm.controls.ConsignorGSTIN.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.ConsignorGSTIN.errors?.required">
                                                    GSTIN is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4" style="display: inline-block;">
                            <div widget class="card border-0 box-shadow">
                                <div class="card-body widget-body">
                                    <h4 style="color: #3E50B4; border-bottom: 1px dotted;">Consignee <small>| DC/DSD Code:
                                            {{dcCode}}</small></h4>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Name:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" formControlName="ConsigneeName" class="form-control"
                                                placeholder="Name" required disabled>
                                            <div *ngIf="!addEditForm.controls.ConsigneeName.valid && (addEditForm.controls.ConsigneeName.dirty || addEditForm.controls.ConsigneeName.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.ConsigneeName.errors?.required">
                                                    Name is required
                                                </div>
                                            </div>
                                            <!-- <mat-select type="text" (selectionChange)="FilterConsignee($event.value)"
                                                formControlName="ConsigneeId" class="form-control"
                                                placeholder="--select consignee--">
                                                <mat-form-field style="width: 100%;">
                                                    <input matInput placeholder="&nbsp;&nbsp;Search Consignee"
                                                        (input)="FilterConsigneeDetails($event.target.value)" />
                                                </mat-form-field>
                                                <mat-option *ngFor="let item of ConsigneeDetailsFilt"
                                                    [value]="item.LookupId">
                                                    {{item.LookupName}}
                                                </mat-option>
                                            </mat-select> -->
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Address Line1:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" maxlength="500"
                                                formControlName="ConsigneeAddress" placeholder="Enter Address"
                                                required disabled/>
                                            <div *ngIf="!addEditForm.controls.ConsigneeAddress.valid && (addEditForm.controls.ConsigneeAddress.dirty || addEditForm.controls.ConsigneeAddress.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.ConsigneeAddress.errors?.required">
                                                    Address is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Address Line2:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" placeholder="Enter Address"
                                                formControlName="ConsigneeAddress2"  disabled />
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Pin:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" formControlName="ConsigneePostcode" class="form-control" disabled
                                                placeholder="Enter Pin" required>
                                            <div *ngIf="!addEditForm.controls.ConsigneePostcode.valid && (addEditForm.controls.ConsigneePostcode.dirty || addEditForm.controls.ConsigneePostcode.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.ConsigneePostcode.errors?.required">
                                                    Pin is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Phone:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" formControlName="ConsigneeMobile"
                                                (keypress)="onlyNumbers($event)" minlength="10" maxlength="10"
                                                class="form-control" placeholder="Enter Mobile No" disabled>
                                            <div *ngIf="!addEditForm.controls.ConsigneeMobile.valid && (addEditForm.controls.ConsigneeMobile.dirty || addEditForm.controls.ConsigneeMobile.touched)"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.ConsigneeMobile.errors?.required">
                                                    Mobile No is required
                                                </div>
                                                <div *ngIf="addEditForm.controls.ConsigneeMobile.errors.minlength">
                                                    Mobile No should contain 10 numbers
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Email:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" email maxlength="50" formControlName="ConsigneeEmail"
                                                class="form-control" placeholder="Enter Email ID">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-5" style="text-align: right;">
                                            GSTN:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" formControlName="ConsigneeGSTIN" maxlength="15"
                                                class="form-control" placeholder="Enter GSTIN" required disabled>
                                            <div *ngIf="!addEditForm.controls.ConsigneeGSTIN.valid && (addEditForm.controls.ConsigneeGSTIN.dirty || addEditForm.controls.ConsigneeGSTIN.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.ConsigneeGSTIN.errors?.required">
                                                    GSTIN is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4" style="display: inline-block; height: 100% !important;">
                            <div widget class="card border-0 box-shadow" style="height: 100% !important;">
                                <div class="card-body widget-body">
                                    <h4 style="color: #3E50B4; border-bottom: 1px dotted;">Consignment Details</h4>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Item:
                                        </div>
                                        <div class="col-sm-7">
                                            <mat-select class="form-control" placeholder="Select Goods Description"
                                                formControlName="GoodsDescId">
                                                <mat-form-field style="width: 100%;">
                                                    <input matInput placeholder="&nbsp;&nbsp;Search Goods Description"
                                                        (input)="FilterDescription($event.target.value)" />
                                                </mat-form-field>
                                                <mat-option *ngFor="let item of goodsDescription"
                                                    [value]="item.LookupId">
                                                    {{item.LookupName}}
                                                </mat-option>
                                            </mat-select>
                                            <div *ngIf="!addEditForm.controls.GoodsDescId.valid && (addEditForm.controls.GoodsDescId.dirty || addEditForm.controls.GoodsDescId.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.GoodsDescId.errors?.required">
                                                    Item Description is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            Approx Weight (Kg):
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control"
                                                placeholder="Weight(Kgs)" formControlName="Weight" disabled>
                                            <!-- <div *ngIf="!addEditForm.controls.Weight.valid && (addEditForm.controls.Weight.dirty || addEditForm.controls.Weight.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.Weight.errors?.required">
                                                    Weight is required
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-sm-5" style="text-align: right;">
                                            PKGS:
                                        </div>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" (keypress)="onlyNumbers($event)" disabled
                                                maxlength="6" placeholder="Enter PKGS" formControlName="NoOfPackages">
                                            <div *ngIf="!addEditForm.controls.NoOfPackages.valid && (addEditForm.controls.NoOfPackages.dirty || addEditForm.controls.NoOfPackages.touched) || isFinalizeClicked"
                                                class="error-text">
                                                <div *ngIf="addEditForm.controls.NoOfPackages.errors?.required">
                                                    PKGS is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <div widget class="card border-0 box-shadow col-sm-12">
                        <div class="card-body widget-body">
                            <h4 style="color: #3E50B4; border-bottom: 1px dotted;">Docket Items</h4>
                            <div class="row form-inline">
                                <div class="form-group col-sm-2 col-12" style="color: black;">Measured Unit: <span
                                        style="font-weight: 500;">&nbsp;Centimeter</span>
                                </div>
                                <div class="form-group col-sm-2 col-12">
                                    <!-- <mat-select placeholder="Measured Unit" [(value)]="FT"
                                        style="width: 100%; border: 1px solid black;color: black;" placeholder="Measured Unit"
                                        class="form-control" disabled>
                                        <mat-option selected value="FT" style="color: black;">Centimeter</mat-option>
                                    </mat-select> -->
                                </div>
                            </div>
                            <table class="table table-hover text-center table-bordered mt-2">
                                <thead class="padding:20px">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">L</th>
                                        <th scope="col">B</th>
                                        <th scope="col">H</th>
                                        <th scope="col">PKGS</th>
                                        <th scope="col">Volumetric Weight(Kgs)</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngIf='boxDetails.length == 0'>
                                        <td colspan="100" class="text-center">No Boxes Found</td>
                                    </tr>
                                    <tr *ngFor='let item of boxDetails;let i=index'>
                                        <td scope="row">
                                            <a *ngIf="item.HUNumber == null && i > 0" (click)="deleteBox(i)">
                                                <i class="btn btn-sm btn-danger fa fa-times" style="cursor: pointer;"
                                                    aria-hidden="true"></i>
                                            </a>
                                            <a *ngIf="item.HUNumber != null || i == 0" disabled>
                                                <i class="btn btn-sm btn-danger fa fa-times"
                                                    style="cursor: not-allowed;" aria-hidden="true"></i>
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            <input [(ngModel)]="item.Length" [ngModelOptions]="{standalone: true}"
                                                maxlength="5" (keyup)="calculateBoxWeight(i)"
                                                (keypress)="onlyNumbers($event)"
                                                style="text-align: center; width: 100%;"
                                                [ngClass]="{'is-invalid': duplicateDimensionError}">
                                        </td>
                                        <td class="text-center">
                                            <input [(ngModel)]="item.Breadth" [ngModelOptions]="{standalone: true}"
                                                maxlength="5" (keyup)="calculateBoxWeight(i)"
                                                (keypress)="onlyNumbers($event)"
                                                style="text-align: center; width: 100%;"
                                                [ngClass]="{'is-invalid': duplicateDimensionError}">
                                        </td>
                                        <td class="text-center">
                                            <input [(ngModel)]="item.Height" [ngModelOptions]="{standalone: true}"
                                                maxlength="5" (keyup)="calculateBoxWeight(i)"
                                                (keypress)="onlyNumbers($event)"
                                                style="text-align: center; width: 100%;"
                                                [ngClass]="{'is-invalid': duplicateDimensionError}">
                                        </td>
                                        <td class="text-center">
                                            <input [(ngModel)]="item.Pkgs" [ngModelOptions]="{standalone: true}"
                                                maxlength="5" (keyup)="calculateBoxWeight(i)"
                                                (keypress)="onlyNumbers($event)"
                                                style="text-align: center; width: 100%;">
                                        </td>
                                        <td class="text-center">
                                            <input [(ngModel)]="item.Weight" [ngModelOptions]="{standalone: true}"
                                                disabled style="text-align: center; width: 100%;">
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-success fa fa-plus" (click)="addBox(1,SINGLE)"
                                                style="margin-left: 10px ;"></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td><b>{{totalPkgs}}</b></td>
                                        <td><b>{{totalVWt}}</b></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <div widget class="card border-0 box-shadow col-sm-12">
                        <div class="card-body widget-body">
                            <h4 style="color: #3E50B4; border-bottom: 1px dotted;">Docket Invoices</h4>
                            <!-- <div class="row form-inline">
                                <div class="form-group col-sm-12" style="color: black;">
                                    <div class="col-sm-12" style="background: lightgray;">
                                        Invoice Value Below Rs. 50K:
                                        <input type="radio" title="Yes" value="yes" name="Invoice"
                                            style="margin-left: 30px;" />
                                        <label for="yes" style="display: inline; margin-left: 10px;">Yes</label>
                                        <input type="radio" title="No" value="no" name="Invoice"
                                            style="margin-left: 30px;" />
                                        <label for="no" style="display: inline;margin-left: 10px;">No</label>
                                    </div>
                                </div>
                            </div> -->
                            <table class="table table-hover text-center table-bordered mt-2">
                                <thead class="padding:20px">
                                    <tr>
                                        <th scope="col">E-waybill No.</th>
                                        <th scope="col">E-waybill Expiry Date</th>
                                        <th scope="col">Inv. No.</th>
                                        <th scope="col">Inv. Date</th>
                                        <th scope="col">Inv. Amt.</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngIf="!invValLessThan50">
                                        <td>
                                            <input type="text" class="form-control" formControlName="EWBNo" (keypress)="onlyNumbers($event)"
                                               minlength="10" maxlength="15" (input)="onInputChange($event.target.value)" />
                                               <div style="color: red;" *ngIf="addEditForm.get('EWBNo').errors?.minlengthStrict">
                                                 Minimum length of 10 characters is required.
                                               </div>
                                        </td>
                                        <td *ngIf="!disableExpDate">
                                            <input type="text" class="form-control" formControlName="EWBExpDate"
                                                [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" name="dt1">
                                            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                                        </td>
                                        <td *ngIf="disableExpDate"></td>
                                        <td>
                                            <input type="text" class="form-control" maxlength="30" formControlName="InvNo"/>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="InvDate" [max]="today"
                                                [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" name="dt2">
                                            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="InvAmt"
                                                (keypress)="priceValidator($event)" (focusout)="invAmtChanged()" />
                                        </td>
                                    </tr>
                                    <tr *ngIf="invValLessThan50">
                                        <td>
                                            <input type="text" class="form-control" formControlName="EWBNo" disabled
                                                maxlength="20" />
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" disabled
                                                formControlName="EWBExpDate">
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" maxlength="30" formControlName="InvNo"/>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="InvDate" [max]="today"
                                                [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" name="dt2">
                                            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="InvAmt"
                                                (keypress)="priceValidator($event)" (focusout)="invAmtChanged()" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-sm-12" style="background: lightgray;">
                                <span style="display: inline;">Invoice Total:</span>
                                <label class="form-label col-sm-2">{{invTotal}}</label>
                                <!-- <input style="display: inline; margin-left: 20px; background-color: lightgray;"
                                    type="text" class="form-control col-sm-2" /> -->
                                <span style="display: inline; margin-left: 20px;">Amount In Words: </span>
                                <span>{{invTotal | numberToWords}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>
    <div class="form-group col-sm-12 mt-2" *ngIf="!this.btSaving">
        <a class="btn btn-primary fa fa-undo" (click)="back()">
            <span> Back To Previous</span>
        </a>

        <button *ngIf="!isEdit" class="btn btn-primary fa fa-eye" style="margin-left: 10px;" (click)="openLCLXPCNPreview()">
            <span> Preview</span>
        </button>

        <button *ngIf="!isXPCNFinalized" class="btn btn-success fa fa-save" style="margin-left: 10px ;"
            (click)="saveXPCN()">
            <span> Save</span>
        </button>

        <button *ngIf="!isXPCNFinalized" class="btn btn-success fa fa-check-circle" style="margin-left: 10px ;"
            (click)="finalizeXPCN(1);">
            <span> Finalise</span>
        </button>

        <button *ngIf="isEdit" class="btn btn-success fa fa-check-circle" style="margin-left: 10px ;"
            (click)="finalizeXPCN(1);">
            <span> Save</span>
        </button>
    </div>
    <div class="form-group col-sm-12 mt-2" *ngIf="this.btSaving">
        <button class="btn btn-primary fa fa-undo btn-disabled">
            <span> Back To Previous</span>
        </button>

        <button *ngIf="isEdit" class="btn btn-primary fa fa-eye btn-disabled" style="margin-left: 10px;">
            <span> Preview</span>
        </button>

        <button *ngIf="!isXPCNFinalized" class="btn btn-success fa fa-save btn-disabled" style="margin-left: 10px ;">
            <span> Save</span>
        </button>

        <button *ngIf="!isXPCNFinalized" class="btn btn-success fa fa-check-circle btn-disabled"
            style="margin-left: 10px ;">
            <span> Finalise</span>
        </button>

        <button *ngIf="isEdit" class="btn btn-success fa fa-check-circle btn-disabled" style="margin-left: 10px ;">
            <span> Save</span>
        </button>
    </div>
</div>
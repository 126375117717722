import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PagesServiceService } from '../pages-service.service';
import { PreviewXpcnComponent } from '../preview-xpcn/preview-xpcn.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-delivered',
  templateUrl: './delivered.component.html',
  styleUrls: ['./delivered.component.scss']
})
export class DeliveredComponent implements OnInit {

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  filterForm: FormGroup;
  downloadFilterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  OrderList = [];
  asn_no = '';
  filterBy = '';
  keyword = '';
  keywordDate = '';
  dateFilter = 'del';
  datetype = 'YTD';
  filterToggle: boolean = true;
  CustomDate: boolean = false;
  user;
  filterCategory = [];
  public readonly MAX_NUMBER_OF_DAYS_FOR_MIS_DATA = 7;
  constructor(private fb: FormBuilder, public _activeRoute: ActivatedRoute,
    private _pageService: PagesServiceService,
    public alertService: AlertService,
    private _datePipe: DatePipe,
    public dialog: MatDialog,
    public _downloadExcelService: DownloadExcelService
  ) {
    if (this._activeRoute.snapshot.queryParams['n'] != null) {
      this.asn_no = this._activeRoute.snapshot.queryParams['n'];
      console.log(this.asn_no, 'delievered')
    } else {
      this.asn_no = ''
    }
    this.user = JSON.parse(localStorage.getItem('userSession'));
    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      FilterBy: new FormControl(""),
      Keyword: new FormControl(""),
      KeywordDate: new FormControl(""),
      OrderByDirection: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      DateType: new FormControl(""),
      DateFilterBy: new FormControl(""),
      Category: new FormControl(null)
    });
    this.downloadFilterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      FilterBy: new FormControl(""),
      Keyword: new FormControl(""),
      KeywordDate: new FormControl(""),
      OrderByDirection: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      DateType: new FormControl(""),
      Category: new FormControl(null)
    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.filterForm.controls["DateFilterBy"].setValue(this.dateFilter);
  }

  ngOnInit(): void {
    // this.filterForm.controls["DateType"].setValue('YTD');

    if (this.asn_no != null || this.asn_no != '') {
      this.filterForm.controls['FilterBy'].setValue('asnno');
      this.filterForm.controls['Keyword'].setValue(this.asn_no);
    }else{
      this.filterForm.controls['FilterBy'].setValue(null);
      this.filterForm.controls['Keyword'].setValue(null);
    }
    this.dateFilterChanged('YTD');

  }

  openLCLXPCNPreview(XPCNID) {
    this._pageService.getLCLXPCNDetailsForFinzalization(XPCNID).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(PreviewXpcnComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  getASnList() {
    this._pageService.GetDeliveredAsnList(this.filterForm.value).subscribe((res) => {
      // console.log(res["Data"]);
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalCount;
      }
    });
  }

  filter() {
    debugger
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['FilterBy'].setValue(this.filterBy);
      if (this.filterBy != 'asndate' && this.filterBy != 'xpcndate' && this.filterBy != 'pdate' && this.filterBy != 'ddate' && this.filterBy != 'ril') {
        if (this.keyword.trim() != '' && this.keyword != null) {
          this.filterForm.controls['Keyword'].setValue(this.keyword.trim());
        } else {
          this.alertService.createAlert("Please enter keyword", 0);
          return;
        }
        this.keywordDate = null;
      }
      else if(this.filterCategory.length>0){
        this.filterForm.controls['Category'].setValue(this.filterCategory);
      }
       else {
        this.keyword = null;
        if (this.keywordDate != '' && this.keywordDate != null) {
          this.filterForm.controls['KeywordDate'].setValue(this._datePipe.transform(this.keywordDate, 'yyyy-MM-dd'));
        } else {
          this.alertService.createAlert("Please select date", 0);
          return;
        }
      }
      this.getASnList();
    } else {
      this.alertService.createAlert("Please select filter by", 0);
      return;
    }
  }

  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.filterBy = '';
    this.keyword = null;
    this.keywordDate = null;
    this.filterCategory = [];
    this.filterForm.controls['FilterBy'].setValue(null);
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls['OrderBy'].setValue(null);
    this.filterForm.controls['OrderByDirection'].setValue(null);
    this.filterForm.controls['KeywordDate'].setValue(null);
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.filterForm.controls['Category'].setValue(null);
    this.getASnList();
  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getASnList();
  }

  fromDate
  toDate

  dateFilterChanged(datetype) {
    console.log(this.dateFilter)
    this.datetype = datetype
    this.filterForm.controls["DateType"].setValue(datetype);

    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    // this.dateFilter = type;
    this.filterForm.controls["DateFilterBy"].setValue(this.dateFilter);

    if (this.dateFilter != '' || this.dateFilter != null) {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    } else {
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);

      this.fromDate = null;
      this.toDate = null;
    }

    this.getASnList();

  }

  datefilterclear() {
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.controls["DateType"].setValue(null);
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.getASnList();
  }

  excelData: any[];

  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.filterForm.controls['EndIndex'].setValue(this.totalSize);
    this._pageService.GetDeliveredAsnList(this.filterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data'];
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({
              'ASN No': element['ASN_Num'],
              'ASN Date': this._datePipe.transform(element['BookingDate'], 'dd-MM-y, (HH:mm)'),
              'XP Booking Id': element['BookingIdCode'],
              'Vendor Code': element['VendorCode'],
              'Vendor Name': element['VendorName'],
              'Vendor Location': element['VendorLoc'],
              'XPCN No': element['XPCNCode'],
              'XPCN Generation Date': this._datePipe.transform(element['XPCNDate'], 'dd-MM-y, (HH:mm)'),
              'No Of Packages': element['NoOfBoxes'],
              'DC Site Code': element['DcCode'],
              'DC Site Location': element['DcLoc'],
              'Picked Date & Time': this._datePipe.transform(element['PickedDate'], 'dd-MM-y, (HH:mm)'),
              'Delivered Date & Time': this._datePipe.transform(element['DeliveryDate'], 'dd-MM-y, (HH:mm)'),
              'Delivery Days': element['DeliveryDays'],
              'RIL Business': element['BusinessType'],
              'Delivery Type': element['DeliveryType'],
              'POD Status': element['PodDocUrl'] == null ? 'POD Not Uploaded' : 'POD Uploaded'
            })
          });
        }
        else {
          this.excelData.push({
            'ASN No': '-',
            'ASN Date': '-',
            'XP Booking Id': '-',
            'Vendor Code': '-',
            'Vendor Name': '-',
            'Vendor Location': '-',
            'XPCN No': '-',
            'XPCN Generation Date': '-',
            'No Of Packages': '-',
            'DC Site Code': '-',
            'DC Site Location': '-',
            'Picked Date & Time': '-',
            'Delivered Date & Time': '-',
            'Delivery Days': '-',
            'POD Status': '-'
          })
        }

        this._downloadExcelService.exportAsExcelFile(this.excelData, 'Delivered');
      }
    })
  }

  bulkPODDownload() {
    let from = this.filterForm.controls["FromDate"].value != null && this.filterForm.controls["FromDate"].value != '' ?
      this._datePipe.transform(this.filterForm.controls["FromDate"].value, 'yyyy-MM-dd') : null;
    let to = this.filterForm.controls["ToDate"].value != null && this.filterForm.controls["ToDate"].value != '' ?
      this._datePipe.transform(this.filterForm.controls["ToDate"].value, 'yyyy-MM-dd') : null;
    // let key = this.filterForm.controls["KeywordDate"].value != null && this.filterForm.controls["KeywordDate"].value != '' ?
    //   new Date(this._datePipe.transform(this.filterForm.controls["KeywordDate"].value, 'yyyy-MM-dd')) : null;

    if (from <= to) {
      const differenceInMilliseconds: number = this.toDate.getTime() - this.fromDate.getTime();
      const differenceInDays: number = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      if (differenceInDays > this.MAX_NUMBER_OF_DAYS_FOR_MIS_DATA) {
        this.alertService.createAlert("Please select date range within 7 days", 0);
      }
      else {
        window.open(environment.apiUrl + 'InvoiceView/DownloadBulkPod?id=' + this.user.UserId
          + "&type=" + this.user.UserType
          + '&from=' + from
          + '&to=' + to
          + '&dateType=' + this.dateFilter
          + '&category=' + this.filterCategory, '_blank');
        }

      }
      else {
        this.alertService.createAlert("From Date should not be greater than To Date", 0);
  
      }
    }



    // this._download.export("https://xpindiadocuments.s3.ap-south-1.amazonaws.com/POD/20000000966_230904073913.jpg").subscribe(data => saveAs(data, 'test.jpg'));
    // FileSaver.saveAs("https://xpindiadocuments.s3.ap-south-1.amazonaws.com/POD/20000000966_230904073913.jpg", "image.jpg");
    // this.downloadFilterForm.controls["OrderBy"].setValue(this.filterForm.controls["OrderBy"].value);
    // this.downloadFilterForm.controls["StartIndex"].setValue(1);
    // this.downloadFilterForm.controls["EndIndex"].setValue(100000);
    // this.downloadFilterForm.controls["FilterBy"].setValue(this.filterForm.controls["FilterBy"].value);
    // this.downloadFilterForm.controls["Keyword"].setValue(this.filterForm.controls["Keyword"].value);
    // this.downloadFilterForm.controls["KeywordDate"].setValue(this.filterForm.controls["KeywordDate"].value);
    // this.downloadFilterForm.controls["OrderByDirection"].setValue(this.filterForm.controls["OrderByDirection"].value);
    // this.downloadFilterForm.controls["FromDate"].setValue(this.filterForm.controls["FromDate"].value);
    // this.downloadFilterForm.controls["DateType"].setValue(this.filterForm.controls["DateType"].value);

    // this._pageService.downloadBulkPOD(this.filterForm.value).subscribe((res) => {

    // });
    // window.open(environment.apiUrl + 'InvoiceView/GetFile', "_blank");
    // this._pageService.GetDeliveredAsnList(this.downloadFilterForm.value).subscribe((res) => {
    //   let list = res["Data"];
    //   if (list != null && list.length > 0) {
    //     list.forEach(x => {
    //      if(x.PodDocUrl != null && x.PodDocUrl != '' && !x.PodDocUrl.includes('.pdf'))
    //        window.open(environment.apiUrl + 'InvoiceView/DownloadPOD?xpcnNo=' + x.XPCNCode + '&url=' + x.PodDocUrl, "_blank");
    //       else if(x.PodDocUrl != null && x.PodDocUrl != '' && x.PodDocUrl.includes('.pdf'))
    //         window.open('https://xpindiadocuments.s3.ap-south-1.amazonaws.com' + x.PodDocUrl, "_blank");
    //     });
    //   }
    // });
  }
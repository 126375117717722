import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview-xpcn',
  templateUrl: './preview-xpcn.component.html',
  styleUrls: ['./preview-xpcn.component.scss']
})
export class PreviewXpcnComponent implements OnInit {

  xpcn: any;

  constructor(public dialogRef: MatDialogRef<PreviewXpcnComponent>,
    private _fb: FormBuilder,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  invoiceHtml: SafeHtml;
  ngOnInit(): void {
    if (this.data != null) {
      if (this.data['xpcn'] != null) {
        this.xpcn = this.data['xpcn'];
        const url = `${environment.apiUrl}InvoiceView/PreviewLCLXPCN?ids=${this.data.xpcn.XPCNId}&isConsignor=false`;
        this.http.get(url, { responseType: 'text' }).subscribe(html => {
          this.invoiceHtml = this.sanitizer.bypassSecurityTrustHtml(html);
        });
      }
    }
  }

  close() {
    this.dialogRef.close()
  }

  downloadPDF() {
    window.open(environment.apiUrl + 'InvoiceView/DownloadLCLXPCNPDF?ids=' + this.xpcn.XPCNId + "&xpcn_code=" + this.xpcn.XPCNCode + '&isConsignor=true', "_blank");
  }

}

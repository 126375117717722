import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PagesServiceService } from '../pages-service.service';
import { MatDialog } from '@angular/material/dialog';
import { MapDialogComponent } from '../shared/map-dialog/map-dialog.component';
import { PreviewXpcnComponent } from '../preview-xpcn/preview-xpcn.component';
import { DatePipe } from '@angular/common';
import { ViewMapLocationComponent } from '../customers/locations/view-map-location/view-map-location.component';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { ActivatedRoute } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as printJS from 'print-js';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-track-lr',
  templateUrl: './track-lr.component.html',
  styleUrls: ['./track-lr.component.scss']
})
export class TrackLrComponent implements OnInit {

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  filterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  OrderList = [];
  filterBy = '';
  keyword = '';
  keywordDate = '';
  dateFilter = 'YTD';
  asn_no = '';
  isAllSelected: boolean = false;
  filterToggle: boolean = true;
  CustomDate: boolean = false;
  user
  filterCategory = [];

  constructor(private fb: FormBuilder,
    private _pageService: PagesServiceService,
    public alertService: AlertService,
    public _activeRoute: ActivatedRoute,
    private _datePipe: DatePipe,
    public dialog: MatDialog,private http: HttpClient,
    public _downloadExcelService: DownloadExcelService
  ) {
    if(this._activeRoute.snapshot.queryParams['n'] != null) {
      this.asn_no = this._activeRoute.snapshot.queryParams['n'];
      console.log(this.asn_no,'asn_list')
     }
    this.user = JSON.parse(localStorage.getItem('userSession'));

    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      FilterBy: new FormControl(""),
      Keyword: new FormControl(""),
      KeywordDate: new FormControl(""),
      OrderByDirection: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      DateType: new FormControl("")
    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
  }

  ngOnInit(): void {
    // this.filterForm.controls["DateType"].setValue('YTD');

    if (this.asn_no != null || this.asn_no != '') {
      this.filterForm.controls['FilterBy'].setValue('asnno');
      this.filterForm.controls['Keyword'].setValue(this.asn_no);
    }else{
      this.filterForm.controls['FilterBy'].setValue(null);
      this.filterForm.controls['Keyword'].setValue(null);
    }
    this.dateFilterChanged('YTD');

  }
  getASnList() {
    this._pageService.GetTrackyourLr(this.filterForm.value).subscribe((res) => {
      // console.log(res["Data"]);
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalCount;
        this.OrderList.forEach(x => {
          if (x['XPCNId'] != null) {
            x.EncryptedOrderId = btoa(x['OrderId'].toString());
            x.EncryptedXPCNId = btoa(x['XPCNId'].toString());
            x.EncryptedASN = btoa(x['ASN_Num'].toString());
          }
        });
      }
    })
  }


  // openLCLXPCNPreview(XPCNID) {
  //   this._pageService.getLCLXPCNDetailsForFinzalization(XPCNID).subscribe(data => {
  //     if (data != null && data['Data'] != null) {

  //       let xpcnDetails = data['Data'];
  //       let goodsDesc = data['Data'].GoodsDescs;
  //       let packageTypes = data['Data'].PackageTypes;
  //       let riskTypes = data['Data'].RiskTypes;
  //       let freightModes = data['Data'].FreightModes;
  //       let invoices = data['Data'].Invoices;
  //       let waybills = data['Data'].WayBills;

  //       let gst = data['Data'].CustomerGST;
  //       let rate = data['Data'].Rate;

  //       // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
  //       // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
  //       let dialogRef = this.dialog.open(PreviewXpcnComponent, {
  //         data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
  //         height: 'auto',
  //         width: '900px',
  //         autoFocus: false
  //       });
  //     }
  //   });
  // }

  filter() {
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['FilterBy'].setValue(this.filterBy);
      if (this.filterBy != 'asndate' && this.filterBy != 'xpcndate' && this.filterBy != 'pdate' && this.filterBy != 'ril') {
        if (this.keyword.trim() != '' && this.keyword != null) {
          this.filterForm.controls['Keyword'].setValue(this.keyword.trim());
        } else {
          this.alertService.createAlert("Please enter keyword", 0);
          return;
        }
        this.keywordDate = null;
      }
      else if(this.filterBy == 'ril'){
        this.filterForm.controls['Keyword'].setValue(this.filterCategory.join(','));
      }
      else {
        this.keyword = null;
        if (this.keywordDate != '' && this.keywordDate != null) {
          this.filterForm.controls['KeywordDate'].setValue(this._datePipe.transform(this.keywordDate, 'yyyy-MM-dd'));
        } else {
          this.alertService.createAlert("Please select date", 0);
          return;
        }
      }
      this.getASnList();
    } else {
      this.alertService.createAlert("Please select filter by", 0);
      return;
    }
  }

  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.filterBy = '';
    this.keyword = null;
    this.keywordDate = null;
    this.filterForm.controls['FilterBy'].setValue(null);
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls['OrderBy'].setValue(null);
    this.filterForm.controls['OrderByDirection'].setValue(null);
    this.filterForm.controls['KeywordDate'].setValue(null);
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.getASnList();
  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getASnList();
  }
  public openMap(item, orderId, Veehcid) {
    let dialogRef = this.dialog.open(ViewMapLocationComponent, {
      data: { data: item, isService: false, orderid: orderId, vehiclenumber: Veehcid },
      height: 'auto',
      width: '600px',
      autoFocus: false,
    });
  }


  fromDate
  toDate

  dateFilterChanged(type) {

    this.filterForm.controls["DateType"].setValue(null);

    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.dateFilter = type;
    this.filterForm.controls["DateType"].setValue(this.dateFilter);

    if (type == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    } else {
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);

      this.fromDate = null;
      this.toDate = null;
    }

    this.getASnList();

  }

  datefilterclear() {
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.controls["DateType"].setValue(null);
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.getASnList();
  }

  openLCLXPCNPreview(XPCNID) {
    this._pageService.getLCLXPCNDetailsForFinzalization(XPCNID).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(PreviewXpcnComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  excelData: any[];

  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.filterForm.controls['EndIndex'].setValue(this.totalSize);
    this._pageService.GetTrackyourLr(this.filterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data'];
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({
              'ASN No': element['ASN_Num'],
              'ASN Date': this._datePipe.transform(element['BookingDate'], 'dd-MM-y, (HH:mm)'),
              'XP Booking Id': element['BookingIdCode'],
              'Vendor Code': element['VendorCode'],
              'Vendor Name': element['VendorName'],
              'Vendor Location': element['VendorLoc'],
              'XPCN No': element['XPCNCode'],
              'XPCN Generation Date': this._datePipe.transform(element['XPCNDate'], 'dd-MM-y, (HH:mm)'),
              'No Of Packages': element['NoOfBoxes'],
              'DC Site Code': element['DcCode'],
              'DC Site Location': element['DcLoc'],
              'Status': element['Status'],
              'RIL Business': element['BusinessType'],
              'Delivery Type': element['DeliveryType'],
              'Picked Date & Time': this._datePipe.transform(element['PickedDate'], 'dd-MM-y, (HH:mm)'),
              'EDD': this._datePipe.transform(element['EDD'], 'dd-MM-y'),
              'Current Location': element['CurrentLocation']
            })
          });
        }
        else {
          this.excelData.push({
            'ASN No': '-',
            'ASN Date': '-',
            'XP Booking Id': '-',
            'Vendor Code': '-',
            'Vendor Name': '-',
            'Vendor Location': '-',
            'XPCN No': '-',
            'XPCN Generation Date': '-',
            'No Of Packages': '-',
            'DC Site Code': '-',
            'DC Site Location': '-',
            'Status': '-',
            'Picked Date & Time': '-',
            'EDD': '-',
            'Current Location': '-',
          })
        }

        this._downloadExcelService.exportAsExcelFile(this.excelData, 'Print & Track XPCN');
      }
    })
  }

  XPCNIds: number[] = [];

  selectAll(event: any): void {
    if (event.checked) {
      this.isAllSelected = event.checked;
      this.XPCNIds = this.OrderList.map(item => item.XPCNId);
    } else {
      this.XPCNIds = [];
    }
  }

  toggleSelection(XPCNId: number, event: any): void {
    if (event.checked) {
      if (!this.XPCNIds.includes(XPCNId)) {
        this.XPCNIds.push(XPCNId);
      }
    } else {
      this.XPCNIds = this.XPCNIds.filter(id => id !== XPCNId);
    }
    console.log(this.XPCNIds)
  }

  printXPCN() {
    const xpcnIdsString = this.XPCNIds.join(',');
    const XPCNIds = {XPCNIds : xpcnIdsString, NoofCopies : 2 }
    const url = `${environment.coreapiUrl}PdfGenerator/DownloadConsignorXPCN`;
    
    this.http.post(url, XPCNIds, { 
      responseType: 'blob', 
      headers: { 'Content-Type': 'application/json' } 
    }).subscribe(
      (response: Blob) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(blob);
  
        // Use print-js to print the PDF
        printJS({
          printable: blobUrl,
          type: 'pdf',
          showModal: true,  // Display print dialog
          onPrintDialogClose: () => {
            // Clean up Blob URL after printing
            window.URL.revokeObjectURL(blobUrl);
          }
        });
      },
      (error) => {
        console.error('Error downloading PDF:', error);
      }
    );
    this.XPCNIds = [];
    this.isAllSelected = false;
  }
}